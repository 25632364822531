import { useTheme, Box, AppBar as MuiAppBar, styled } from '@mui/material'
import type { ReactNode } from 'react'
import { Suspense, lazy } from 'react'

import { useUser } from '@src/common/hooks/useUserContext'
// import type { AppBarProps } from '@src/common/types'
import MiniDrawer from '@src/layouts/sidebar'

const SubscriptionPlans = lazy(async () => import('@src/features/Subscription'))

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })<AppBarProps>(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(['width', 'margin'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
//   backgroundColor: theme.palette.primary.main,
// }))

const PrivateLayout = ({ children }: { children: ReactNode }) => {
  const theme = useTheme()
  const { isSubscribed } = useUser()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Suspense fallback={<div />}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <>
              {!isSubscribed ? (
                <SubscriptionPlans />
              ) : (
                <Suspense fallback={<div />}>
                  <MiniDrawer>
                    <Suspense fallback={<div />}>{children}</Suspense>
                  </MiniDrawer>
                </Suspense>
              )}
            </>
          </Box>
        </Suspense>
      </Box>
    </Box>
  )
}

export default PrivateLayout
