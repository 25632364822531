import { Route, Routes } from 'react-router-dom'

import PrivateLayout from '@src/layouts/PrivateLayout'
import PublicLayout from '@src/layouts/PublicLayout'

import { PrivateRouteWrapper } from './PrivateRoutes'
import { publicRoutesLayout, privateRoutesLayout } from './routes'

const Router = () => {
  return (
    <Routes>
      {privateRoutesLayout.map((route, idx) => (
        <Route
          key={idx + (route.path ?? '')}
          path={route.path}
          element={
            <PrivateLayout>
              <PrivateRouteWrapper component={() => route.element} />
            </PrivateLayout>
          }
        />
      ))}
      {publicRoutesLayout.map((route, idx) => (
        <Route
          key={idx + (route.path ?? '')}
          path={route.path}
          element={<PublicLayout>{route.element}</PublicLayout>}
        />
      ))}
    </Routes>
  )
}

export default Router
