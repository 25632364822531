import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import type { CardType } from '@src/features/SearchResults/types'
import { capitalizeFirstLetter } from '@src/utils/helpers'

import { useGetStatsV2Query } from '../Dashboard/AnalyticsServices'
import { useSearchQuery } from '../SearchPage/SearchServices'

const excludeKeys = (entries: [string, string][], keysToExclude: string[]) => {
  return entries.filter(([key]) => !keysToExclude.includes(key))
}

const useSearchResults = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { search, pathname } = location

  // Memoize URLSearchParams based on 'search'
  const filtersObject = useMemo(() => {
    const params = new URLSearchParams(search)
    const entries = Array.from(params.entries())

    // Process `country` as a number array if it exists
    const filters: { [key: string]: any } = Object.fromEntries(
      excludeKeys(entries, ['schema', 'query', 'categoryKey']),
    )

    if (filters.country) {
      filters.country = filters.country
        .split(',')
        .map((id: string) => parseInt(id, 10))
    }

    return filters
  }, [search])

  // Extract parameters
  const schema = useMemo(() => {
    const params = new URLSearchParams(search)
    return (params.get('schema') as 'company' | 'person') || 'company'
  }, [search])

  const query = useMemo(() => {
    const params = new URLSearchParams(search)
    return params.get('query') || ''
  }, [search])

  const categoryKey = useMemo(() => {
    const params = new URLSearchParams(search)
    return params.get('categoryKey')
  }, [search])

  const { data, isLoading, isFetching } = useSearchQuery(
    {
      schema: schema as 'company' | 'person',
      query: query,
      filters: filtersObject,
    },
    { skip: !schema || !query },
  )

  const skipAnalytics = !data

  // Analytics query
  const {
    data: analyticsData,
    error: analyticsError,
    isLoading: isLoadingAnalytics,
  } = useGetStatsV2Query({ query, entity: schema }, { skip: skipAnalytics })

  // Prevent infinite navigation loop using a ref
  const hasNavigated = useRef(false)

  useEffect(() => {
    if (
      !categoryKey &&
      pathname === '/search-result' &&
      !hasNavigated.current
    ) {
      const newParams = new URLSearchParams(search)
      newParams.set('categoryKey', 'adverse_media')

      // Set the flag before navigation to prevent re-entry
      hasNavigated.current = true

      navigate(`${pathname}?${newParams.toString()}`, {
        replace: true,
      })
    }
  }, [categoryKey, navigate, pathname, search])

  const selectedCategoryItem =
    !isLoading && data ? data[categoryKey || 'adverse_media'] : []

  const results = data?.adverse_media?.length || 0
  const totalItemsInCategory = selectedCategoryItem?.length || 0

  const calculateLength = (data: any) => {
    if (!data) return 0

    return Object.values(data).reduce(
      (total: number, item) => total + (Array.isArray(item) ? item.length : 0),
      0,
    )
  }

  const lenghtPerCategories = useMemo(() => {
    if (!data) return {}

    return Object.entries(data).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: Array.isArray(value) ? value.length : 0,
      }),
      {},
    )
  }, [data])

  const length = calculateLength(data)

  const title = capitalizeFirstLetter(
    `${data?.entity} check: ${data?.query} (${length})`,
  )

  const handleCategoryChange = (cardType: CardType) => {
    const newParams = new URLSearchParams(search)
    newParams.set('categoryKey', cardType)

    navigate(`${pathname}?${newParams.toString()}`, {
      replace: true,
    })
  }

  const [sortOrder, setSortOrder] = useState<string>('Relevance')

  const sortedCategoryItems = useMemo(() => {
    if (!selectedCategoryItem) return []

    let sortedItems = [...selectedCategoryItem]

    switch (sortOrder) {
      case 'Newest':
        sortedItems.sort(
          (a, b) =>
            new Date(b.start_date).getTime() - new Date(a.start_date).getTime(),
        )
        break
      case 'Oldest':
        sortedItems.sort(
          (a, b) =>
            new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
        )
        break
      case 'Relevance':
        sortedItems = selectedCategoryItem
        break
      default:
        break
    }
    return sortedItems
  }, [sortOrder, selectedCategoryItem])

  const [selectedCards, setSelectedCards] = useState<{
    [key: string]: Set<number>
  }>({
    adverse_media: new Set(),
    litigation: new Set(),
    sanction_watchlist_pep: new Set(),
    corporate_wealth: new Set(),
  })

  const handleSelectedAllCards = (selectAll: boolean) => {
    setSelectedCards((prevSelectedCards) => ({
      ...prevSelectedCards,
      [categoryKey as string]: selectAll
        ? new Set(sortedCategoryItems.map((item: any) => item.id))
        : new Set(),
    }))
  }

  const { buffer, loadMoreItems } = useBuffer(sortedCategoryItems)

  return {
    lenghtPerCategories,
    selectedCategoryKey: categoryKey,
    selectedCategoryItem: buffer,
    totalItemsInCategory,
    handleCategoryChange,
    schema,
    title,
    data,
    isLoading,
    isFetching,
    results,
    loadMoreItems,
    setSortOrder,
    sortOrder,
    handleSelectedAllCards,
    selectedCards,
    setSelectedCards,

    analyticsData,
    analyticsError,
    isLoadingAnalytics,
  }
}

export default useSearchResults

const useBuffer = (data: any) => {
  const maxBuffer = 10
  const [buffer, setBuffer] = useState<any[]>([])
  const pointer = useRef(0)
  const hasInitialized = useRef(false) // Tracks if buffer has been initialized

  useEffect(() => {
    // Only reset buffer when `data` changes meaningfully
    if (!hasInitialized.current || data.length > 0) {
      setBuffer(data.slice(0, maxBuffer))
      pointer.current = maxBuffer
      hasInitialized.current = true
    }
  }, [data])

  const loadMoreItems = () => {
    setBuffer((prevBuffer: any[]) => {
      const nextPointer = pointer.current + maxBuffer
      const newItems =
        nextPointer < data.length
          ? data.slice(pointer.current, nextPointer)
          : data.slice(pointer.current)

      pointer.current = Math.min(nextPointer, data.length)
      return [...prevBuffer, ...newItems]
    })
  }

  return { buffer, loadMoreItems }
}
