import { createApi } from '@reduxjs/toolkit/query/react'

import type { User } from '@src/features/auth/types'
import { baseQueryWith401Handling } from '@src/utils/apiBaseQuery'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWith401Handling,
  endpoints: (builder) => ({
    getUserInfo: builder.mutation<User, void>({
      query: () => ({
        url: '/auth/info',
        method: 'POST',
      }),
    }),
    changePassword: builder.mutation<void, { newPassword: string }>({
      query: (params) => ({
        url: '/auth/change-password',
        method: 'POST',
        body: {
          new_password: params.newPassword,
        },
      }),
    }),
    deactivateAccount: builder.mutation<void, void>({
      query: () => ({
        url: '/auth/deactivate',
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useGetUserInfoMutation,
  useChangePasswordMutation,
  useDeactivateAccountMutation,
} = userApi
