import type { LazyExoticComponent } from 'react'
import { Suspense, lazy } from 'react'
import { Navigate, type RouteProps } from 'react-router-dom'

export type RoutesProps = {
  path: RouteProps['path']
  element: React.ReactElement
  exact?: boolean
}

const LoadComponent = ({
  component: Component,
}: {
  component: LazyExoticComponent<React.FC>
}) => {
  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  )
}

const authRoutes: RoutesProps[] = [
  {
    path: '/login',
    element: (
      <LoadComponent
        component={lazy(async () => import('@src/features/auth/Login'))}
      />
    ),
  },
  {
    path: '/register',
    element: (
      <LoadComponent
        component={lazy(async () => import('@src/features/auth/Register'))}
      />
    ),
  },
  {
    path: '/forgot-password',
    element: (
      <LoadComponent
        component={lazy(async () => import('@src/features/auth/ResetPassword'))}
      />
    ),
  },
  {
    path: '/confirmation',
    element: (
      <LoadComponent
        component={lazy(
          async () => import('@src/features/auth/MailConfirmation'),
        )}
      />
    ),
  },
]

const privateRoutes: RoutesProps[] = [
  {
    path: '/search',
    element: (
      <LoadComponent
        component={lazy(async () => import('@src/features/SearchPage'))}
      />
    ),
  },
  {
    path: '/search-result',
    element: (
      <LoadComponent
        component={lazy(async () => import('@src/features/SearchResults'))}
      />
    ),
  },
  {
    path: '/dashboard',
    element: (
      <LoadComponent
        component={lazy(async () => import('@src/features/Dashboard'))}
      />
    ),
  },
  {
    path: '/account-settings',
    element: (
      <LoadComponent
        component={lazy(
          async () => import('@src/features/user/AccountSettings'),
        )}
      />
    ),
  },
]

export const publicRoutesLayout = [
  { path: '/', element: <Navigate to="/login" /> },
  ...authRoutes,
]

export const privateRoutesLayout = [
  { path: '/', element: <Navigate to="/search" /> },
  ...privateRoutes,
]
