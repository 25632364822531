import { Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import DarkModeLogo from '@src/assets/images/logoWhite.webp'
import type { ThemeLogoProps } from '@src/common/types'

const ThemeLogo: React.FC<ThemeLogoProps> = () => {
  return (
    <Link to="/search">
      <Box
        component="img"
        src={DarkModeLogo}
        alt="Logo"
        sx={{
          height: {
            xs: 70,
            md: 75,
            lg: 80,
          },
        }}
      />
    </Link>
  )
}

export default ThemeLogo
