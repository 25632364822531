import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface ReportDetails {
  id: string
  title: string
}

interface ReportState {
  reports: Record<string, string[]>
  reportDetails: Record<string, ReportDetails[]>
}

const initialState: ReportState = {
  reports: {},
  reportDetails: {},
}

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    addReports: (
      state,
      action: PayloadAction<{ category: string; ids: string | string[] }>,
    ) => {
      const { category, ids } = action.payload

      if (!state.reports[category]) {
        state.reports[category] = []
      }

      if (Array.isArray(ids)) {
        state.reports[category] = Array.from(
          new Set([...state.reports[category], ...ids]),
        )
      } else {
        if (!state.reports[category].includes(ids)) {
          state.reports[category].push(ids)
        }
      }
    },

    addReportDetails: (
      state,
      action: PayloadAction<{
        category: string
        details: ReportDetails | ReportDetails[]
      }>,
    ) => {
      const { category, details } = action.payload

      if (!state.reportDetails[category]) {
        state.reportDetails[category] = []
      }

      if (Array.isArray(details)) {
        state.reportDetails[category] = Array.from(
          new Set([...state.reportDetails[category], ...details]),
        )
      } else {
        if (!state.reportDetails[category].some((r) => r.id === details.id)) {
          state.reportDetails[category].push(details)
        }
      }
    },

    removeReport: (
      state,
      action: PayloadAction<{ category: string; id: string }>, // Changed id type to string
    ) => {
      const { category, id } = action.payload
      if (state.reports[category]) {
        state.reports[category] = state.reports[category].filter(
          (reportId) => reportId !== id,
        )

        if (state.reports[category].length === 0) {
          delete state.reports[category]
        }
      }

      if (state.reportDetails[category]) {
        state.reportDetails[category] = state.reportDetails[category].filter(
          (report) => report.id !== id,
        )

        if (state.reportDetails[category].length === 0) {
          delete state.reportDetails[category]
        }
      }
    },

    clearReports: (state) => {
      state.reports = {}
      state.reportDetails = {}
    },
  },
})

export const { addReports, addReportDetails, removeReport, clearReports } =
  reportSlice.actions
export default reportSlice.reducer
