import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWith401Handling } from '@src/utils/apiBaseQuery'

interface FiltersObject {
  [key: string]: string | string[]
}

interface SearchQueryParams {
  schema: 'company' | 'person'
  query: string
  filters: FiltersObject
}

interface Country {
  id: number
  label: string
}

const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: baseQueryWith401Handling,
  endpoints: (builder) => ({
    search: builder.query<any, SearchQueryParams>({
      query: (data) => ({
        url: '/search/v2',
        method: 'POST',
        body: {
          query: data.query,
          entity: data.schema,
          filters: data.filters,
        },
      }),
    }),
    getCountryList: builder.query<Country[], void>({
      query: () => ({
        url: 'https://api.clarytas.com/country',
        method: 'GET',
      }),
      transformResponse: (response: any[]) =>
        response.map((country, index) => ({
          id: index + 1,
          label: country.name || country.label,
        })),
    }),
  }),
})

export const { useSearchQuery, useGetCountryListQuery } = searchApi
export default searchApi
