import { getLocalStorage } from '@src/common/hooks/useLocalStorage'

export const encodeBase64UrlSafe = (obj: any) => {
  const jsonStr = JSON.stringify(obj)
  return btoa(jsonStr)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
}

export const prepareAuthHeaders = (headers: any, p0: unknown) => {
  const authData = getLocalStorage('AUTH')
  const token = authData ? JSON.parse(authData).sessionId : null

  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  return headers
}

export const parseGeoCode = (geoCode: string) => {
  const [lat, lng] = geoCode
    .replace('@', '')
    .split(',')
    .map((coord) => parseFloat(coord.trim()))
  return { lat, lng }
}

export const extractJurisdictionData = (jurisdictionCode: any) => {
  // Get the first key in the object (there should be only one key)
  const key = Object.keys(jurisdictionCode)[0]
  const data = jurisdictionCode[key]

  // Parse the geo_code
  const { lat, lng } = parseGeoCode(data.geo_code)

  return {
    name: data.name,
    lat,
    lng,
  }
}

export function capitalizeFirstLetter(str: string) {
  return str?.charAt(0).toUpperCase() + str?.slice(1)
}

export const colorScale: string[] = [
  '#FF6F61', // Coral
  '#FF8C00', // Dark Orange
  '#FFA07A', // Light Salmon
  '#FF6347', // Tomato
  '#FF4500', // Orange Red
  '#FFD700', // Gold
  '#FFB6C1', // Light Pink
  '#FF1493', // Deep Pink
  '#FF69B4', // Hot Pink
  '#FF8C69',
]

export const generateColors = (numColors: number) => {
  const colors = []
  const baseHue = 45 // Starting hue in the warm color range (orange/yellow)
  const hueStep = 30 // Step between hues to ensure distinct colors

  for (let i = 0; i < numColors; i++) {
    const hue = (baseHue + i * hueStep) % 360 // Wrap around hue values
    colors.push(`hsl(${hue}, 80%, 85%)`) // Lighter colors with increased lightness
  }
  return colors
}

export const getRandomColor = () => {
  const baseHue = Math.floor(Math.random() * 60) // Random hue in the warm range (0° to 60°)
  const lightness = Math.floor(Math.random() * 20) + 70 // Lightness between 70% and 90%
  return `hsl(${baseHue}, 80%, ${lightness}%)`
}
