import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import type { UserContextType } from '@src/common/types'
import { useGetUserInfoMutation } from '@src/features/user/UserServices'

import { useAuthContext } from './useAuthContext'

const UserContext = createContext<UserContextType | undefined>(undefined)

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isSubscribed, setIsSubscribed] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)
  const [subscriptionPlan, setSubscriptionPlan] = useState<any>(null)
  const [userInfo, setUserInfo] = useState<any>(null)

  const [getUserInfo] = useGetUserInfoMutation()
  const { isAuthenticated } = useAuthContext()
  const navigate = useNavigate()

  const fetchUserInfo = async () => {
    setIsLoading(true)
    try {
      const userInfo = await getUserInfo().unwrap()
      setSubscriptionPlan(userInfo.subscription_plan)
      setUserInfo({
        api_key: userInfo.api_key ?? '',
        email: userInfo.email ?? '',
        logins: userInfo.logins ?? '',
        subscription_plan: userInfo.subscription_plan ?? { active: false },
      })
      setIsSubscribed((userInfo.subscription_plan as any)?.active ?? false)
      // setIsSubscribed(false)
    } catch (err) {
      const status = err?.status || err?.data?.status

      if (status === 401) {
        navigate('/login')
      } else {
        setError(err)
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserInfo()
    }
  }, [isAuthenticated])

  return (
    <UserContext.Provider
      value={{
        userInfo,
        isSubscribed,
        isLoading,
        error,
        subscriptionPlan,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
