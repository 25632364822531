import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'

import { getLocalStorage } from '@src/common/hooks/useLocalStorage'
import type { RootState } from '@src/store'

// Prepare headers by setting the Authorization token if available
export const prepareAuthHeaders = (headers: Headers, state: RootState) => {
  const authData = getLocalStorage('AUTH')
  const token = authData ? JSON.parse(authData).sessionId : null

  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  return headers
}

const apiBaseUrl = import.meta.env.VITE_API_URL

const baseQueryWithReauth = fetchBaseQuery({
  baseUrl: apiBaseUrl,
  prepareHeaders: (headers, { getState }) => {
    prepareAuthHeaders(headers, getState as unknown as RootState)
    return headers
  },
})

export const baseQueryWith401Handling: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQueryWithReauth(args, api, extraOptions)

  if (result.error && result.error.status === 401) {
    // Clear 'AUTH' from local storage if 401
    localStorage.removeItem('AUTH')
    // Redirect to login page
    window.location.href = '/login'
  }

  return result
}
