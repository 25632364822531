import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWith401Handling } from '@src/utils/apiBaseQuery'

interface StatsQueryParams {
  query: string
  entity: string
}

const analyticsApi = createApi({
  reducerPath: 'analyticsApi',
  baseQuery: baseQueryWith401Handling,
  endpoints: (builder) => ({
    getStatsV2: builder.query<any, StatsQueryParams>({
      query: (params) => ({
        url: '/stats/v2',
        method: 'POST',
        body: {
          query: params.query,
          entity: params.entity,
        },
      }),
    }),
  }),
})

export const { useGetStatsV2Query } = analyticsApi
export default analyticsApi
