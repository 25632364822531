import { useSnackbar } from 'notistack'
import type { ReactNode } from 'react'
import React, { useCallback, useEffect, createContext, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLocalStorage } from '@src/common/hooks/useLocalStorage'

const AuthContext = createContext<
  | {
      isAuthenticated: boolean
      saveSession: (auth: { sessionId: string; expires: number }) => void
      removeSession: () => void
    }
  | undefined
>(undefined)

export function useAuthContext() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider')
  }
  return context
}

const authSessionKey = 'AUTH'

export function AuthProvider({ children }: { children: ReactNode }) {
  const [session, setSession, removeValue] = useLocalStorage<{
    sessionId: string
    expires: number
  } | null>(authSessionKey, null)

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const isAuthenticated = !!session

  const saveSession = useCallback(
    ({ sessionId, expires }: { sessionId: string; expires: number }) => {
      setSession({ sessionId, expires })
    },
    [setSession],
  )

  const removeSession = useCallback(() => {
    removeValue()
    navigate('/login') // Redirect to login on session removal
  }, [removeValue, navigate])

  useEffect(() => {
    if (session) {
      const currentTime = Math.floor(Date.now() / 1000)
      const timeUntilExpiration = session.expires - currentTime

      if (timeUntilExpiration <= 0) {
        // Session already expired, handle immediately
        removeSession()
        enqueueSnackbar('Your session has expired. Please log in again.', {
          variant: 'warning',
        })
      } else {
        // Set a timeout to log out when the session expires
        const timeoutId = setTimeout(() => {
          removeSession()
          enqueueSnackbar('Your session has expired. Please log in again.', {
            variant: 'warning',
          })
        }, timeUntilExpiration * 1000)

        // Clear timeout if session changes or component unmounts
        return () => clearTimeout(timeoutId)
      }
    }
  }, [session, removeSession, enqueueSnackbar])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        saveSession,
        removeSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
