import { createApi } from '@reduxjs/toolkit/query/react'

import type {
  CheckoutSessionRequest,
  CheckoutSessionResponse,
} from '@src/features/Subscription/types'
import { baseQueryWith401Handling } from '@src/utils/apiBaseQuery'

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery: baseQueryWith401Handling,
  endpoints: (builder) => ({
    createCheckoutSession: builder.mutation<
      CheckoutSessionResponse,
      CheckoutSessionRequest
    >({
      query: (body) => ({
        url: '/create-checkout-session',
        method: 'POST',
        body,
      }),
    }),
    manageSubscription: builder.mutation({
      query: (body) => ({
        url: `/manage-billing`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useCreateCheckoutSessionMutation,
  useManageSubscriptionMutation,
} = subscriptionApi
