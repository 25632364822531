import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import type { ReactNode } from 'react'
import React, { useRef, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import type { MobileDrawerProps } from '@src/common/types'

import DrawerList from './DrawerList'

const MobileDrawer: React.FC<MobileDrawerProps & { children: ReactNode }> = ({
  open,
  setOpen,
}) => {
  const drawerRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const location = useLocation()
  const { search } = location

  const schema = useMemo(() => {
    const params = new URLSearchParams(search)
    return (params.get('schema') as 'company' | 'person') || 'company'
  }, [search])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node)
      ) {
        handleDrawerClose()
      }
    }

    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  return (
    <Drawer
      sx={{ minWidth: 260 }}
      anchor="left"
      open={open}
      onClose={handleDrawerClose}
    >
      <Box
        ref={drawerRef}
        sx={{
          width: 300,
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.primary
              : theme.palette.primary.main,
          height: '100%',
        }}
        role="presentation"
      >
        <DrawerList open={open} schema={schema} showHeader={true} />
      </Box>
    </Drawer>
  )
}

export default MobileDrawer
